@import '../../../../public/assets/scss/base/variables';

.site-footer {
  min-height: 200px;
  position: relative;
  background: $dark;
  padding-top: 70px;
  font-size: 14px;
  color: $gray4;
  line-height: 24px;

  @media (max-width: 992px) {
    padding-top: 30px;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      line-height: 20px;
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 10px;
      letter-spacing: 0.4px;
      display: flex;
      flex-wrap: wrap;
      .icon-list-text {
        color: $white;
      }
      i {
        padding-right: 18px;
      }
    }
  }

  .row {
    align-items: center;
    justify-content: space-around;
  }

  .logo-container {
    @media (max-width: 991px) and (min-width: 768px) {
      transform: translate(-30px, 0);
    }

    @media (max-width: 767px) {
      transform: translate(-80px, 0);
    }

    @media (max-width: 575px) {
      margin: 0 auto;
      transform: none;
    }

    @media (max-width: 415px) {
      width: 200px;
    }

    display: flex;
    align-items: center;
    height: 100%;
    width: 400px;

    img {
      height: 100%;
      width: 100%;
    }
  }

  .footer-address {
    .icon-list-icon {
      width: 22%;
    }
    .icon-list-text {
      width: 75%;
    }
  }

  .footer-container {
    padding: 0px 30px 40px 30px;
    @media (max-width: 992px) {
      padding: 0;
    }
  }

  .copyright {
    display: flex;
    justify-content: center;
    font-size: 11px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.3px;
    a {
      color: $primary;
      &:hover {
        border-style: solid;
        border-width: 0px 0px 1px 0px;
        border-color: #e33d3d;
      }
    }
  }

  @media (max-width: 1024px) {
    .footer-address {
      .icon-list-icon {
        width: 100%;
      }
      .icon-list-text {
        width: 100%;
      }
    }
  }
}
