@import '../../../../../public/assets/scss/base/variables';

.header-logo-container {
  display: flex;
  height: 100px;
  max-width: 400px;

  img {
    height: 100%;
    width: 100%;
    transform: scale(3);
    margin-top: 30px;
  }
}

.desktop-menu {
  @media (max-width: 991px) {
    display: none !important;
  }
}
