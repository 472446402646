@import '../../../../public/assets/scss/base/variables';

.proximity-to-amenities-container {
  background-color: #f8f8f8;
  padding: 130px 60px;
  @media (max-width: 991px) and (min-width: 768px) {
    padding: 90px 30px;
    h2 {
      font-size: 40px;
    }
  }
  @media (max-width: 767px) {
    padding: 60px 15px;
    h2 {
      font-size: 35px;
    }
    h3 {
      font-size: 25px;
    }
  }
  .proxmity-to-amentities-detailed {
    padding: 10px;
    @media (max-width: 992px) {
      padding: 15px !important;
    }
  }

  .list-container {
    display: flex;
    flex-direction: column;

    .list-container-box {
      display: flex;
      align-items: center;

      .list-container-icon {
        margin-right: 20px;

        i {
          font-size: 40px;
          color: $primary;
        }
      }
      .list-container-text {
        margin-bottom: 0;
      }
    }
  }
}
