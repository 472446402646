@import '../../../../public/assets/scss/base/variables';

.location-benefit-container {
  padding: 130px 60px;
  @media (max-width: 991px) and (min-width: 768px) {
    padding: 90px 30px;
    h2 {
      font-size: 40px;
    }
  }
  @media (max-width: 767px) {
    padding: 60px 15px;
    h2 {
      font-size: 35px;
    }
    h3 {
      font-size: 25px;
    }
  }
  .location-benefits {
    @media (max-width: 991px) {
      flex-direction: column-reverse;
    }
  }
}
