@import '../../../../public/assets/scss/base/variables';
.ourservices {
  padding: 90px 0 60px;
  background-color: #f8f8f8;
  background-image: url(/assets/imagewebp/ourservices/img_bg.webp);
  position: relative;
  background-position: center;
  @media (max-width: 992px) {
    padding: 40px 0 20px 0;
  }
}
.featured-box {
  &.style-2 {
    background-color: $white;
    padding: 40px;
    margin-bottom: 30px;
    transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    @media (max-width: 992px) {
      padding: 30px;
    }
    .featured-box-title {
      color: $headings-color;
    }
    .featured-box-description {
      color: $body-color;
    }
    &:hover {
      background-color: #3f4448;
      transform: translateY(-5px);
      -webkit-transform: translateY(-5px);
      .featured-box-title {
        color: $white;
      }
    }
  }
}
.icon-box {
  padding-bottom: 15px;
  margin-bottom: 20px;
  border-bottom: 1px solid $body-color;
  .icon-box-icon {
    margin-bottom: 15px;
    i {
      color: $primary;
      font-size: 40px;
      line-height: 1;
    }
  }
  .icon-box-content {
    .icon-box-title {
      color: $headings-color;
      font-size: 16px;
      letter-spacing: 0.8px;
      height: 35px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
.icon-list {
  ul {
    padding: 0;
  }
  .icon-list-items {
    display: flex;
    list-style-type: none;
    text-align: left;
    padding-bottom: 15px;
    .icon-list-icon {
      display: inline-block;
      margin-right: 15px;
      border: 2px solid #f8f8f8;
      border-radius: 100%;
      min-width: 25px;
      height: 25px;
      line-height: 20px;
      text-align: center;
      i {
        color: $primary;
        font-size: 14px;
        line-height: 1;
      }
    }
    .icon-list-text {
      font-size: 14px;
      font-weight: 500;
      color: $headings-color;
    }
  }
  li:hover {
    .icon-list-icon {
      border-color: $primary;
    }
  }
}
