@import '../../../../public/assets/scss/base/variables';
.countup-wrap {
  margin-bottom: 3rem;
  @media (max-width: 992px) {
    text-align: center;
  }
  .counter-number {
    font-size: 36px;
    font-family: $headings-font-family;
    text-transform: uppercase;
    color: $headings-color;
    line-height: 36px;
  }
  .counter-title {
    font-size: 13px;
    text-transform: uppercase;
    letter-spacing: 0.33px;
  }
  .countup-box {
    position: relative;
    padding-bottom: 30px;
    margin-right: 50px;
    @media (max-width: 992px) {
      margin-right: 0px;
    }
    &:after {
      content: '';
      height: 10px;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      background: #f8f8f8;
      z-index: -2;
      @media (max-width: 992px) {
        content: none;
      }
    }
    &:before {
      content: '';
      left: 0;
      background-color: $primary;
      position: absolute;
      bottom: 0;
      right: 0;
      width: 0;
      height: 10px;
      z-index: -1;
      transition: width 0.7s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
      @media (max-width: 992px) {
        content: none;
      }
    }
    &:hover {
      &:before {
        width: 100%;
      }
    }
  }
  &.style {
    .countup-box {
      padding-bottom: 0;
      margin-right: 0;
      &:before {
        height: 3px;
        width: 100px;
        top: 50%;
        right: -20%;
        left: auto;
        z-index: 1;
        @media (max-width: 992px) {
          content: none;
        }
      }
      &:after {
        content: none;
      }
    }
    [class*='col-']:last-child {
      .countup-box {
        &:before {
          content: none;
        }
      }
    }
  }
}
.building-counter {
  padding: 80px 0;
  background-color: #f8f8f8;
  background-image: url(/assets/imagewebp/ourservices/img_bg.webp);
  position: relative;
  background-position: center;
  @media (max-width: 992px) {
    padding: 40px 15px !important;
  }
}
